import React, { useEffect, useState, useRef } from "react";
import { Navbar, Button, Container, NavbarToggler, Collapse, Nav, NavItem,
    Label,
    DropdownMenu,
    DropdownToggle,
    DropdownItem,
    UncontrolledButtonDropdown, } from "reactstrap";
import Logo from "../../assets/img/logoNextSixBlack.svg";
import '../../styles/components/header2.scss';
import headermenu from "../../assets/img/headerMenu.svg";
import headermenuclose from "../../assets/img/headerMenuClose.svg";
import personLogo from "../../assets/img/getStartedPersonLogo.svg"
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { setLang } from '../../redux/actions/langActions';
import { useLocation, useParams } from "react-router";
import {MdLanguage} from "react-icons/md";
import { setRefresh2 } from "../../redux/actions/chartActions";
import MAPPING from "../../utils/mapping";
import seoComponent from "../../businesses/seoComponent";
import { decodeUrlLocationV2 } from "../../utils/url";
// import silverKetupat from "../../assets/img/Silver.svg"

const Header2 = () => {
    const { t, i18n } = useTranslation();
    const languages = [{ label: 'EN', value: 'en' }, { label: 'BM', value: 'bm' }, { label: 'CN', value: 'zh' }];
    const dispatch = useDispatch();
    const selectedLang = useSelector(state => state.lang);
    const signInUrl = process.env.REACT_APP_SIGN_IN_URL;
    const [isopen, setisopen] = useState(false)
    const getwidth = window.innerWidth;
    const headerSiteNavigation = MAPPING.HeaderSiteNavigation

    const toggle = () => {
        setisopen(!isopen)
    }

    useEffect(() =>{
        if(getwidth < 768 && isopen ==true){
            setisopen(false)
        }
    },[getwidth])

    const location = useLocation()
    const paramsObj = useParams();

    const filterData = useSelector(state => state.seoData);
    const titleOptions = filterData.titles || [];
    const auctionTitleOptions = filterData.bidNowPropertyTypes || [];

    useEffect(() => {
        const header = document.getElementById("header");
        const sticky = header.offsetTop;
        const scrollCallBack = window.addEventListener("scroll", () => {
            if (window.pageYOffset > sticky) {
                header.classList.add("sticky2");
            } else {
                header.classList.remove("sticky2");
            }
        });
        return () => {
            window.removeEventListener("scroll", scrollCallBack);
        };
    }, []);

    const scrollToSignUp = (selector) => {
        const header = document.getElementById("header");
        const el = document.querySelector(selector);
        const sticky = header.offsetHeight;
        const rect = el.getBoundingClientRect().top + window.pageYOffset - sticky;
        window.scrollTo({ top: rect, behavior: 'smooth' });
    }

    const changeLanguage = (event) => {
        assignLanguage(event.target.value);
    };

    const assignLanguage = (value) =>{
        dispatch(setLang(value));
        i18n.changeLanguage(value);
        setTimeout(()=> dispatch(setRefresh2(true)), 10);
    };

    const displaySelectedLanguage = () =>{
        const matched = languages.find(({ value }) => value === selectedLang);
        return matched?.label;
    };

    const handleButtonDropdown = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };
    const handleButtonDropdownMobile = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const processLink = (navigation, link) => {
        let returnLink = "";
        const pathDecode = decodeUrlLocationV2({ location, useParams: { ...paramsObj }, titleOptions, auctionTitleOptions});
        const pathname = location.pathname + location.search;
        const isSales = pathname.includes('/properties-for-sale');
        const isRent = pathname.includes('/properties-for-rent');
        const isBlog = pathname.includes("blog");
        const isAgent = pathname.includes("agent");

        const params = location.pathname.split("/");

        if (isBlog) returnLink = link;
        else if (isAgent) {
            if (navigation === "rent" || navigation === "buy") {
                const state = params[2];
                const area = params[3];
                const seoState = state !== "all-states" ? "/" + seoComponent.treatState(seoComponent.decodeQueryParam(state)) : "";
                const seoArea = area !== "all-areas" ? "/" + seoComponent.treatArea(seoComponent.decodeQueryParam(area)) : "";
                returnLink = link + (seoArea ? seoState + seoArea : seoState ? seoState : ""); 
            } else returnLink = link;
        } else {
            // Sales n Rent Page
            switch (navigation) {
                case "rent":
                    returnLink = isSales ? pathname.replace(/sale/g, "rent").replace(/page.?\d/g, "") : link;
                    break;
                case "buy":
                    returnLink = isRent ? pathname.replace(/rent/g, "sale").replace(/page.?\d/g, "") : link;
                    break;
                case "findAgent":
                    const state = pathDecode.state ?? "all-states";;
                    const area = pathDecode.area ?? "all-areas";;
                    returnLink = `/agent/${seoComponent.treatState(state)}/${seoComponent.treatArea(area)}/all-types`;
                    break;
                default:
                    returnLink = link;
                    break;
            }
        }

        window.location.href = returnLink;
    };

    const today = new Date()
    const etaStart = new Date('2023-04-27T16:00:00.000Z')
    const etaEnd = new Date('2023-04-30T16:00:00.000Z')

    return (
        <Navbar fixed="top" className={ 'header2 ' + (location.pathname === '/thankyou' && 'header-thankyou') } id="header">
            <Container className="header-content2">
                <div className="header-left2">
                    <a href="/">
                        <img
                            className="nextsix-logo2"
                            src={Logo}
                            alt="Nextsix Logo"
                        />
                    </a>
                </div>
                <Nav className="header-site-navigation-menu">
                    {Object.keys(headerSiteNavigation).map(navigation => (
                        <NavItem key={navigation}>
                            <a rel="headerSiteNavigation" onClick={() => processLink(navigation, headerSiteNavigation[navigation].link)} target={headerSiteNavigation[navigation].element === 'nav-blog' ? "_blank" : "_self"} className="header-site-navigation-menu-btn">
                                {t(`${headerSiteNavigation[navigation].element}`)}
                            </a>
                        </NavItem>
                    ))}
                </Nav>
                <div className="header-right2">
                    <div className="btn-desktop-view">
                        <Button href={signInUrl} target="_blank" className="btn-sign-in2">
                            {t('agent-login')}
                        </Button>
                        {location.pathname !== "/signup" ? <Button href="/signup" className="btn-get-started2">
                            {t('get-started')}
                        </Button> : <Button className="btn-get-started2" onClick={() => scrollToSignUp('#signupForm')}>{t('sign-up')}</Button>}
                        <span className="language-dropdown-padding">
                            <UncontrolledButtonDropdown className="language-dropdown-filter">
                            <DropdownToggle
                                caret
                                color="transparent"
                                size="m"
                                className="text-capitalize language-dropdown-toggle"
                                onClick={handleButtonDropdown}//tbd
                            >
                                {selectedLang ? (
                                    <span className="search-filter-label"><MdLanguage/> {displaySelectedLanguage()}</span>
                                ) : (
                                <span className="search-filter-label">
                                    <MdLanguage/> {'Choose'}
                                </span>
                                )}
                            </DropdownToggle>
                            <DropdownMenu
                                className={
                                languages && languages.length > 4//tbd
                                    ? "language-dropdown-menu-scroll"
                                    : "language-dropdown-menu"
                                }
                            >
                                {languages.map((lang, idx) => {//tbd
                                    return (
                                        lang.value === selectedLang ? 
                                        <DropdownItem key={idx} className="language-dropdown-option-selected" disabled={true}>
                                            {lang.label}
                                        </DropdownItem>
                                        : <DropdownItem key={idx} onClick={() => assignLanguage(lang.value)}>
                                            {lang.label}
                                        </DropdownItem>
                                    );
                                })}
                                 {/* {((etaStart<=today)&&(today<=etaEnd))&& <img src={silverKetupat} className="language-dropdown-ketupat" ></img>} */}
                               
                            </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </span> 
                    </div>
                    
                    <NavbarToggler onClick={() => toggle()} >{isopen ? <img src={headermenuclose} className="headerMenuLogo" alt="" /> : <img src={headermenu} className="headerMenuLogo" alt="" />}</NavbarToggler>
                    <Collapse isOpen={isopen} navbar>
                        <Nav className="ml-auto" navbar>
                            <NavItem>
                                <Button href={process.env.REACT_APP_SIGN_IN_URL} target="_blank" className="btn-sign-in2"> {t('agent-login')}</Button>
                            </NavItem>

                            <NavItem>
                                <Button href="/about" className="btn-sign-in2">
                                    {t('about-us')}
                                </Button>
                            </NavItem>

                            <NavItem>
                                <Button href="/properties-for-sale" className="btn-sign-in2">
                                    {t('nav-buy')}
                                </Button>
                            </NavItem>


                            <NavItem>
                                <Button href="/properties-for-rent" className="btn-sign-in2">
                                    {t('nav-rent')}
                                </Button>
                            </NavItem>


                            <NavItem>
                                <Button href="/agent/all-states/all-areas/all-types" className="btn-sign-in2">
                                    {t('nav-find-agent')}
                                </Button>
                            </NavItem>


                            <NavItem>
                                <Button href="https://blog.nextsix.com/" target="_blank" className="btn-sign-in2">
                                    {t('nav-blog')}
                                </Button>
                            </NavItem>


                            <NavItem>
                                <Button href="/contact" className="btn-sign-in2">
                                    {t('contact-us')}
                                </Button>
                            </NavItem>
                            <NavItem>
                                {location.pathname !== "/signup" ? <Button href="/signup" className="btn-sign-in2">
                                    {t('get-started')} <img src={personLogo} alt="" />
                                </Button> : <Button className="btn-sign-in2" onClick={() => scrollToSignUp('#signupForm')}>{t('sign-up')} <img src={personLogo} alt="" /></Button>}
                            </NavItem>

                            <NavItem>
                                {/* <span className="language-list-mobile">
                                    <MdLanguage className="language-list-img-mobile" /> 
                                    <select
                                    className="language-list-select-mobile"
                                    name="languageDropdown"
                                    onChange={changeLanguage}
                                    value={selectedLang}
                                    >
                                    {languages.map((lang, index) => {
                                        return (
                                        <option
                                            className="language-list-option-mobile"
                                            key={index}
                                            value={lang.value}
                                        >
                                            {lang.label}
                                        </option>
                                        );
                                    })}

                                
                                    {((etaStart<=today)&&(today<=etaEnd))&&<span> <img src={silverKetupat} className="language-dropdown-ketupat" >"" </img></span>}
                                
                                    </select>
                                   
                                    
                                </span> */}
                                   <span className="language-dropdown-padding-mobile">
                                        <UncontrolledButtonDropdown className="language-dropdown-filter-mobile">
                                        <DropdownToggle
                                            caret
                                            color="transparent"
                                            size="m"
                                            className="text-capitalize language-dropdown-toggle-mobile"
                                            onClick={handleButtonDropdownMobile}//tbd
                                        >
                                            {selectedLang ? (
                                                <span className="search-filter-label"><MdLanguage/> {displaySelectedLanguage()}</span>
                                            ) : (
                                            <span className="search-filter-label">
                                                <MdLanguage/> {'Choose'}
                                            </span>
                                            )}
                                        </DropdownToggle>
                                        <DropdownMenu
                                            className={
                                            languages && languages.length > 4//tbd
                                                ? "language-dropdown-menu-scroll-mobile"
                                                : "language-dropdown-menu-mobile"
                                            }
                                        >
                                            {languages.map((lang, idx) => {//tbd
                                                return (
                                                    lang.value === selectedLang ? 
                                                    <DropdownItem key={idx} className="language-dropdown-option-selected-mobile" disabled={true}>
                                                        {lang.label}
                                                    </DropdownItem>
                                                    : <DropdownItem key={idx} onClick={() => assignLanguage(lang.value)}>
                                                        {lang.label}
                                                    </DropdownItem>
                                                );
                                            })}
                                            {/* {((etaStart<=today)&&(today<=etaEnd))&& <img src={silverKetupat} className="language-dropdown-ketupat-mobile" ></img>} */}
                                        
                                        </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </span> 
                            </NavItem>
                        </Nav>
                    </Collapse>


                </div>
            </Container>
        </Navbar>

    );
};

export default Header2;


