import React, { useEffect, useState } from 'react';
import moment from "moment";
import lib from "../../businesses/lib";
import SetupData from "../../data/SetupData";
import house from "../../assets/img/housegrey.svg";
import car from "../../assets/img/cargrey.svg";
import shower from "../../assets/img/bathgrey.svg";
import bed from "../../assets/img/bedgrey.svg";
import landGreyIcon from "../../assets/img/icon_land_grey.svg";
import {
  Card,
  CardImg,
} from "reactstrap";
import Avatar from "../../components/Avatar";
import locIcon from "../../assets/img/pin_rounded_circle.svg";
import thumbup from "../../assets/img/thumbup.svg";
import { useTranslation } from 'react-i18next';
import SliderImg from '../SliderImg';

const PropertyCard = (props) => {
  const { t } = useTranslation();
  const property = props.property;
  const [location, setLocation] = useState('')
  const [imageTimeStamp] = useState(new Date().getTime())
  //const agent = property.agent && property.agent;

  // const handlePopulateData = () => {
  //   if (props.callback) props.callback(property, props.type);
  // };
  useEffect(() => {
    if (props.loc) {
      setLocation(props.loc);
    }
  }, [props.loc]);
  return (
    <>
      <Card
        className={`property-list-card-body ${property.isBestDeal ? "bestDeal-card" : ""} ${location ? location : ""}`}
        style={{ cursor: "pointer" }}
        title={`View ${property.name}`}
      // onClick={() => handlePopulateData(property)}
      >
        {
          property.isBestDeal && property.images.length > 0 ? 
            <SliderImg
              isListing={true}
              images={property.images}
              originalAlt={property.for === "Rent" || property.for === "Buy" ?
                `${property.type} For ${property.for === "Rent" ? "Rent" : "Sale"} 
                in ${property.address?.area} ${property.address?.state.replace('Wilayah Persekutuan ', '').trim()}`
                : undefined}
            ></SliderImg> :
            <CardImg
              className="property-list-cardImg2"
              loading="lazy"
              src={
                property.images && property.images.length > 0
                  ? `${property.images[0]}?${imageTimeStamp}`
                  : SetupData.defaultHouse
              }
    
              // alt={property &&
              //   (property.for === "Rent" || property.for === "Buy") &&
              //   (property.type + " " + (property.for === "Rent" ? "For Rent" : "For Sale") + " in " +
              //     property.address?.area + " " + property.address?.state.replace(`Wilayah Persekutuan`, ``).trim())}
    
              alt={property.for === "Rent" || property.for === "Buy" ?
                `${property.type} For ${property.for === "Rent" ? "Rent" : "Sale"} 
                in ${property.address?.area} ${property.address?.state.replace('Wilayah Persekutuan ', '').trim()}`
                : undefined}
            />
        }
        <div className="property-list-card-row-body2 property-list-card-row-body2-v2">
          <div className="property-row-tagGroup2">
            <div className="property-row-saleTag2-wrapper">
              {property.isBestDeal &&
                <div className="property-row-saleTag2 bestDeal">
                  <img loading="lazy" src={thumbup} className="bestDeal-icon" alt="" />
                  <span>Best Deal</span>
                </div>
              }
              <div className={`property-row-saleTag2 ${property.for?.toLowerCase()}`}>
                {property.for ? <>{
                  props.type === "properties" ?
                    (property.for.toLowerCase() === "buy"
                      // ? lib.toTitleCase(t('sale'))
                      ? lib.toTitleCase(t('buy'))
                      : lib.toTitleCase(t('rent')))
                    : lib.toTitleCase(t('auction'))
                  }</>
                  : lib.toTitleCase(t('auction'))
                }
                {/* {property.for === "Buy" ? "Sale" : property.for} */}
              </div>
              {property.rentType === 'Room Only' && 
                <div className="property-row-saleTag2 rent-type">
                  {property.rentType}
                </div>
              }
            </div>
            <div className="property-row-createdTag2 property-row-createdTag2-v2">
            {property.auctionDate ? 
              <>{`${t('auction-date-time')} ` + moment(property.auctionDate ? property.auctionDate : property.created).format("DD/MM/YYYY")}</>
              :
              <>{`${t('last-updated-on')} ` + moment(property.updated ? property.updated : property.created).format("DD/MM/YYYY")}</>
            }
            </div>
          </div>
          <div className="property-row-locGroup2">
            <img loading="lazy" src={locIcon} className="locIcon2" alt="" />
            <p className="property-row-locText2">{property.address.area} {property.address.area ? "," : ""} {property.address.state}</p>
          </div>
          {property.for ? <>
            {props.type === "properties" ?
              (property.for.toLowerCase() === "rent" ? 
                <p className="property-row-priceText2">
                  {`RM ${parseFloat(property.rent.monthlyRental).toLocaleString()}/${t('month')}`}
                </p>
                : 
                <p className="property-row-priceText2">
                  RM {parseFloat(property.buy.price).toLocaleString()}
                </p>
              ) : 
              <p className="property-row-priceText2">
                RM {parseFloat(property.price).toLocaleString()}
              </p>
            }</> 
            :
            <p className="property-row-priceText2">
              RM {parseFloat(property.price).toLocaleString()}
            </p>
          }
          {/* Dynamic Header Tag */}
          <h3 className="property-row-nameText2">
            {property.name}&nbsp;
          </h3>
          <p className="property-row-nameText3"> 
            {(() => {
              if (property.title === 'Agricultural Land' || property.title === 'Commercial Land'
                || property.title === 'Industrial Land' || property.title === 'Residential Land') {
                if (property.landArea) {
                  return <>{property.landArea} sqft </>
                }
              } else if (property.title === 'Apartment/Flat' || property.title === 'Condo/Serviced Residence'
                || property.title === 'Terrace/Link/Townhouse' || property.title === 'Semi-D/Bungalow'
                || property.title === 'Hotel/Resort' || property.title === 'Luxury Suites' || property.title === 'Suites'
              ) {
                return <>{property.beds != 0 && property.beds != "Bedrooms" && property.beds != "Beds" && property.beds && `${property.beds} Bedrooms `}</>
              } else if (property.title === 'Shop/Office/Retail Space' || property.title === 'Factory/Warehouse') {
                if (property.buildup) {
                  return <>{property.buildup} sqft </>
                }
              }
            })()}
            {property.type} For{" "}
            {property.for ? <>
              {props.type === "properties" ?
                (property.for.toLowerCase() === "buy"
                  ? lib.toTitleCase(t('sale'))
                  : lib.toTitleCase(t('rent')))
                : lib.toTitleCase(t('auction'))
              }</>
              :
              lib.toTitleCase(t('auction'))
            } in {property.address.state}, {property.address.area}
          </p>
          <div className="property-row-iconsGroup2-wrapper">
            {/* Beds is from auction/ Bedrooms is from sale rent / hide it  */}
            {property.beds != 0 && property.beds != "Bedrooms" && property.beds != "Beds" && property.beds &&
              <span className="property-row-iconsGroup2">
                <img loading="lazy" src={bed} alt="" className="property-row-iconImg2" />
                <span className="property-row-iconText2">{property.beds}</span>
              </span>
            }
            {property.baths != 0 && property.baths &&
              <span className="property-row-iconsGroup2">
                <img loading="lazy" src={shower} alt="" className="property-row-iconImg2" />
                <span className="property-row-iconText2">{property.baths}</span>
              </span>
            }
            {property.carParks !== null && property.carParks !== undefined && 
              <span className="property-row-iconsGroup2">
                <img loading="lazy" src={car} alt="" className="property-row-iconImg2" />
                <span className="property-row-iconText2">{property.carParks}</span>
              </span>
            }
            {(() => {
              if (property.title === 'Agricultural Land' || property.title === 'Commercial Land'
                || property.title === 'Industrial Land' || property.title === 'Residential Land') {
                if (property.landArea) {
                  return <span className="property-row-iconsGroup2">
                    <img loading="lazy" src={landGreyIcon} alt="" className="property-row-iconImg2" />
                    <span className="property-row-iconText2">{property.landArea} sqft</span>
                  </span>
                }
              } else {
                if (property.buildup) {
                  return (
                    <span className="property-row-iconsGroup2">
                      <img loading="lazy" src={house} alt="" className="property-row-iconImg2" />
                      <span className="property-row-iconText2">{property.buildup} sqft</span>
                    </span>
                  )
                }
              }
            })()}
          </div>
        </div>
        {property.agent && props.showAgent && (
          <>
            <div className="property-row-footer2">
              <div className="property-row-footerGroup2">
                <Avatar
                  className="property-row-footerAvatar2"
                  src={
                    property.agent.image
                      ? property.agent.image
                      : SetupData.profile.imgURL
                  }
                />
                <div className="property-row-footerAgentInfo2">
                  <span className="property-row-footerCompany2">{property.agent.companyName}</span>
                  <span className="property-row-footerAgentName2">{property.agent.nickname || property.agent.name}</span>
                </div>
              </div>
            </div>
          </>
        )}
      </Card>
    </>
  );
};

export default PropertyCard;