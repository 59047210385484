import React, { useState, useEffect } from 'react';
import { copyIcon, fbIcon, whatsappIcon } from '../assets/icons/icon';
import "../styles/components/shareSection.scss";

/**
 * Social media share with FB, Whatsapp, Copy Link features
 * @param {object} props Component props
 * @param {string} props.generalShare General link to share, default to this value if others not passed in
 * @param {string} props.fbShare Link to share to FB, if different from `generalShare`
 * @param {string} props.WAShare Link to share to Whatsapp, if different from `generalShare`
 * @param {string} props.copyShare Link to copy for share, if different from `generalShare`
 * @param {function} props.getShareText function to get text with embedded Link to share for WA & Copy Link only: (link) => 'Some text {link} ...some text'
 */
export default function ShareSection({ generalShare, fbShare, WAShare, copyShare, getShareText = (link) => link }) {
    const [copied, setCopied] = useState(false)

    useEffect(() => {
        if (copied) {
            setTimeout(() => {
                setCopied(false)
            }, 1500)
        }
    }, [copied])

    const handleFacebookShare = () => {
        const link = fbShare || generalShare;
        window.open(`https://facebook.com/sharer/sharer.php?u=${link}`)
    }

    const handleWhatsappShare = () => {
        const link = WAShare || generalShare;
        const text = getShareText(link)
        window.open(`https://wa.me/?text=${text}`)
    }

    const handleCopyLink = async () => {
        const link = copyShare || generalShare;
        const text = getShareText(link)
        try {
            await navigator.clipboard.writeText(text);
            setCopied(true);
        } catch (error) {
            console.error(error.message);
        }
    }

    return (
        <div className='shareSec'>
            <p className="shareSec-text">Share via</p>
            <div className="shareSec-btn-wrapper">
                <button className="shareSec-copy" onClick={handleCopyLink}>
                    <img className="shareSec-copy-icon" src={copyIcon} alt="copy-link" />
                    {copied ? 'Copied' : 'Copy Link'}
                </button>

                <button className="shareSec-fb" onClick={handleFacebookShare}>
                    <img className="shareSec-fb-icon" src={fbIcon} alt="facebook-share" />
                    Facebook
                </button>

                <button className="shareSec-WA" onClick={handleWhatsappShare}>
                    <img className="shareSec-WA-icon" src={whatsappIcon} alt="whatsapp-share" />
                    WhatsApp
                </button>
            </div>
        </div>
    )
}