import React from 'react';
import ShareSection from './ShareSection';

export default function PropertyShare({ propertyId }) {
    const webLink = `${process.env.REACT_APP_WEBSITE}/property-listing/${propertyId}`;
    const appLink = `${process.env.REACT_APP_APPBE}/p/${propertyId}`;

    const getShareText = (link) => {
        return `Hey, I just came across this amazing property that I think might be perfect for you!
Check it out ${link} and let me know what you think.`
    }

    return (
        <>
            <ShareSection generalShare={webLink} copyShare={appLink} getShareText={getShareText}/>
        </>
    )
}